/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Checkbox, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import TableLoadingOverlay from "../../components/MaterialTableLoadingOverlay";
import MaterialTable from "../../components/Table";
import { getClientSpecificTransform, postClientSpecificMapping, editClientMapping, deleteClientSpecificMapping, getStandardHeaders } from "../../utils/api";

export function DisplayClientSpecificMapping() {
  const { client, studyFileType, id } = useParams();
  const study = studyFileType.split(":")[0];
  const headerLookup = {};
  const [data, setData] = useState([]);
  const [dropDownData, setDropdownData] = useState([]);
  const [isOpen, setIsOpen] = useState({ status: false, message: "", severity: "" });
  const { data: transformMapping, error: transformMappingError, mutate } = useSWR(`/lms/api/v1/transform/client-specific-transform/${id}`, () => getClientSpecificTransform(id));
  const { data: standardHeaders, error: standardHeadersError } = useSWR(`/lms/api/v1/transform/standard-header/${study}`, () => getStandardHeaders(study));
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  useEffect(() => {
    if (transformMapping) {
      transformMapping.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setData(transformMapping);
    }
    if (standardHeaders) {
      standardHeaders.standard_headers.sort((a, b) => (a.standard_header_name > b.standard_header_name ? 1 : -1));
      setDropdownData(standardHeaders.standard_headers);
    }
  }, [transformMapping, standardHeaders]);
  /* eslint-disable*/
  if (dropDownData.length > 0) {
    dropDownData.map((row) => headerLookup[row.standard_header_name] = row.standard_header_name?.toString());
  }
  if (transformMappingError) return <div>Error loading client specific transforms...</div>;
  if (standardHeadersError) return <div>Error loading standard header transforms...</div>;
  const columns = [
    { header: "File Header Mapping", accessorKey: "header_name" },
    {
      header: "Standard Header Mapping",
      accessorKey: "standard_header_name",
      Edit: ({ row }) => (
        <Autocomplete
          defaultValue={row.original.standard_header_name ?? ""}
          options={Object.values(headerLookup)}
          onChange={(event, newValue) => {
            row._valuesCache.standard_header_name = newValue;
          }}
          renderInput={(params) => <TextField {...params} label="Standard Header" />}
        >
        </Autocomplete>
      )
    },
    {
      header: "Required",
      accessorKey: "is_required",
      Edit: ({ row }) => (
        <Checkbox
          defaultChecked={row.original.is_required}
          onChange={(event) => {
            row._valuesCache.is_required = event.target.checked;
          }}
        >
        </Checkbox>
      ),
      Cell: ({ row }) => (
        row.original.is_required ? <CheckIcon /> : <ClearIcon />
      )
    },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen((prev) => ({ ...prev, status: false, message: "", severity: "" }));
  };
  return (
    <div>
      {transformMapping ? (
        <MaterialTable
          columns={columns}
          options={{
            headerStyle: {
              backgroundColor: "#30455c",
            },
            paging: true,
            pageSize: 20,
            pageSizeOptions: [],
            emptyRowsWhenPaging: false,
          }}
          data={data}
          title={`Header Mapping for ${client} - ${studyFileType}`}
          editable={{
            onRowAdd: async (newData) => {
              const rowToAdd = {
                preprocessing_file_type: parseInt(id, 10),
                header_name: newData.header_name.toUpperCase(),
                standard_header_name: newData.standard_header_name,
                is_required: !!newData?.is_required,
              };
              if (data.filter(row => row.header_name === rowToAdd.header_name).length === 0) {
                await postClientSpecificMapping(rowToAdd);
                mutate();
              } else {
                setIsOpen((prev) => ({
                  ...prev, status: true, message: "Header name already mapped!", severity: "error",
                }));
              }
            },
            onRowUpdate: async (newData, oldData) => {
              const rowToUpdate = {
                preprocessing_file_type: oldData.preprocessing_file_type,
                header_name: newData.header_name.toUpperCase(),
                standard_header_name: newData.standard_header_name,
                is_required: !!newData?.is_required
              }
              await editClientMapping(oldData.id, rowToUpdate);
              mutate();
            },
            onRowDelete: async (oldData) => {
              await deleteClientSpecificMapping(oldData.id);
              mutate();
              setIsOpen((prev) => ({
                ...prev, status: true, message: "Transform Type deleted successfully", severity: "success",
              }));
            },
          }}
        />
      ) : <TableLoadingOverlay />
      }
      {isOpen.status && (
        <Snackbar
          open={isOpen}
          onClose={() => setIsOpen((prev) => ({ ...prev, status: false }))}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <div><Alert onClose={handleClose} severity={isOpen.severity}>
            {isOpen.message}
          </Alert></div>
        </Snackbar>
      )}
    </div>
  );
}
