export const isGroup = (field) => field && Object.keys(field?.fields)?.length;

export function traverseGraphQLTree(tree, parent = "") {
  const result = [];

  if (parent?.length) {
    result.push(parent);
  }
  if (isGroup(tree)) {
    Object.keys(tree.fields).forEach((key) => {
      result.push(...traverseGraphQLTree(tree.fields[key], `${parent}.${key}`));
    });
  }

  return result;
}

// "foo.bar.baz" --> ["foo", "foo.bar", "foo.bar.baz"]
export function addParentNodes(fieldKey) {
  const subFields = fieldKey.split(".");
  let fullFieldKey = "";
  const fieldKeysWithParentNodes = [];
  subFields.forEach((field, index) => {
    fullFieldKey = fullFieldKey.concat(
      `${index === 0 ? "" : "."}${field}`,
    );
    fieldKeysWithParentNodes.push(fullFieldKey);
  });
  return fieldKeysWithParentNodes;
}

export function removeLonelyParentNodes(fieldKey, selectedFields) {
  // First get all nodes associated with the key
  const allParentNodes = addParentNodes(fieldKey);
  // Then remove them from the selected fields
  const cleanedSelectedFields = selectedFields.filter(f => !allParentNodes.includes(f));
  // Add them back if they are still relevant to another subnode
  const relevantParent = new Set();
  allParentNodes.forEach(parent => {
    cleanedSelectedFields.forEach(f => {
      if (f.includes(parent)) {
        relevantParent.add(parent);
      }
    });
  });
  return [...cleanedSelectedFields, ...relevantParent];
}
